import * as firebase from "firebase";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_CONSOLE_APIKEY,

    authDomain: "wild-cow-saloon-2023.firebaseapp.com",

    projectId: "wild-cow-saloon-2023",

    storageBucket: "wild-cow-saloon-2023.appspot.com",

    messagingSenderId: "447303397361",

    appId: "1:447303397361:web:bbf40a222b52d7b73e9e50",

    measurementId: "G-43P7SK3ZVS"

};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// export
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
